import type { GameLOCBoxscore } from '../../shared/utils/stats'
import type { PlayerSeasonBoxscoreStats } from '@/lib/hooks/usePlayerSeasonBoxscoreStats'
import React, { useMemo } from 'react'
import { useQueries } from '@tanstack/react-query'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ListItem from '@mui/material/ListItem'
import GroupsIcon from '@mui/icons-material/Groups'
import { descending } from 'd3-array'
import { useBreakPoints } from '../../lib/hooks'
import { queryKey as teamQueryKey, getTeamByTeamId } from '../../lib/hooks/useTeam'
import ImageWithFallback from '../ImageWithFallback'
import { GameLOC } from '../GameLOC'
import PlayerSeasonBoxscore from '../form/PlayerSeasonBoxScore'
import WatchListWidgetPlayerViewLoadingSkeleton from './WatchListWidgetPlayerViewLoadingSkeleton'
import { safeRatio } from '@/lib/utils/math'

type WatchListPlayerViewSeasonStatsProps = Partial<Pick<DTO.WatchListPlayerInfo, 'currentTeamInfo' | 'seasonStats'>> & {
    isWatchListInfoLoading?: boolean
}
const WatchListPlayerViewSeasonStats = ({
    isWatchListInfoLoading,
    seasonStats,
    currentTeamInfo,
}: WatchListPlayerViewSeasonStatsProps): JSX.Element => {
    const { isMobile } = useBreakPoints()

    const [playerSeasonStats, teamIds] = useMemo(() => {
        if (!seasonStats) return [undefined, []]

        const mostRecentTeamByLeague = seasonStats
            .filter((s) => s.teamId)
            .reduce<{ [l in Enum.League]?: string }>((acc, s) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                if (!acc[s.league]) acc[s.league] = s.teamId!
                return acc
            }, {})

        const teamStatLines = seasonStats
            .sort((a, b) => descending(a.gp, b.gp))
            .filter((s) => !s.teamId && s.season && s.league !== 'Summer')
            .map<{
                stats: PlayerSeasonBoxscoreStats[]
                gameAvgBoxscore: GameLOCBoxscore
                league: Enum.League
                teamId: string
            }>((s) => {
                const stats = {
                    ppg: safeRatio(s.pts, s.gp),
                    mpg: safeRatio(s.min, s.gp),
                    '2fg%': safeRatio(s['2pm'], s['2pa']),
                    '3fg%': safeRatio(s['3pm'], s['3pa']),
                    rbpg: safeRatio(s.drb + s.orb, s.gp),
                    orbpg: safeRatio(s.orb, s.gp),
                    drbpg: safeRatio(s.drb, s.gp),
                    astpg: safeRatio(s.ast, s.gp),
                    tovpg: safeRatio(s.to, s.gp),
                    stlpg: safeRatio(s.stl, s.gp),
                    blkpg: safeRatio(s.blk, s.gp),
                    gs: s.gs,
                    gp: s.gp,
                    'ft%': safeRatio(s.ftm, s.fta),
                }
                const gameAvgBoxscore = {
                    min: stats.mpg,
                    trb: stats.rbpg,
                    ast: stats.astpg,
                    stl: stats.stlpg,
                    blk: stats.blkpg,
                    to: stats.tovpg,
                    pts: stats.ppg,
                    '3pm': safeRatio(s['3pm'], s.gp),
                    '3pa': safeRatio(s['3pa'], s.gp),
                    '2pa': safeRatio(s['2pa'], s.gp),
                    '2pm': safeRatio(s['2pm'], s.gp),
                    fta: safeRatio(s.fta, s.gp),
                }
                return {
                    stats: [stats],
                    gameAvgBoxscore,
                    league: s.league,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    teamId: mostRecentTeamByLeague[s.league]!,
                }
            })
        const playerTeamIds = new Set<string>(teamStatLines.map((s) => s.teamId))
        return [teamStatLines, Array.from(playerTeamIds)]
    }, [seasonStats])

    const teams = useQueries({
        queries: teamIds.map((teamId) => ({
            queryKey: teamQueryKey(teamId),
            queryFn: () => getTeamByTeamId(teamId),
        })),
    })
    const teamsByTeamId = useMemo(
        () =>
            teams
                .filter((t) => t.data)
                .reduce<Record<string, DTO.Team | undefined>>((acc, { data }) => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    if (!(data!.teamId in acc)) acc[data!.teamId] = data
                    return acc
                }, {}),
        [teams]
    )

    const isLoading = isWatchListInfoLoading || teams.some((q) => q.isLoading)

    if (isLoading)
        return (
            <ListItem>
                <WatchListWidgetPlayerViewLoadingSkeleton type="season" />
            </ListItem>
        )

    if (playerSeasonStats?.length) {
        return (
            <>
                {playerSeasonStats.map(({ league, stats, gameAvgBoxscore, teamId }) => {
                    const seasonStatTeam = teamsByTeamId[teamId]
                    return (
                        <ListItem key={`${league}-${teamId}`}>
                            <Grid container rowSpacing={1}>
                                <Grid container item xs={12} display="flex" wrap="nowrap">
                                    {seasonStatTeam && (
                                        <Grid item xs={8} display="flex" alignItems="center">
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    height: 30,
                                                    minWidth: 30,
                                                    marginRight: 0.5,
                                                }}
                                            >
                                                {seasonStatTeam.urlSlug && (
                                                    <ImageWithFallback
                                                        src={seasonStatTeam.url}
                                                        alt="team logo"
                                                        fallbackSize={{ xs: 30 }}
                                                        priority
                                                    >
                                                        <GroupsIcon />
                                                    </ImageWithFallback>
                                                )}
                                            </Box>
                                            <Typography variant="body2" fontWeight="medium">
                                                {seasonStatTeam.teamName}
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Grid item xs="auto" marginLeft="auto" display="flex">
                                        <GameLOC
                                            boxscore={gameAvgBoxscore}
                                            league={league}
                                            text={isMobile ? 'Gm. Avg.' : 'Game Avg.'}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <PlayerSeasonBoxscore
                                        boxscores={stats}
                                        isLoading={isLoading}
                                        overrideStatCategories={[
                                            'ppg',
                                            'mpg',
                                            '2fg%',
                                            '3fg%',
                                            'ft%',
                                            'rbpg',
                                            'astpg',
                                            'orbpg',
                                            'drbpg',
                                            'tovpg',
                                            'stlpg',
                                            'blkpg',
                                            'gp',
                                            'gs',
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </ListItem>
                    )
                })}
            </>
        )
    }

    return (
        <ListItem>
            <Grid container rowSpacing={1}>
                {currentTeamInfo && (
                    <Grid item xs={8} display="flex" alignItems="center">
                        <Box
                            sx={{
                                position: 'relative',
                                height: 30,
                                minWidth: 30,
                                marginRight: 0.5,
                            }}
                        >
                            {currentTeamInfo.urlSlug && (
                                <ImageWithFallback
                                    src={currentTeamInfo.url}
                                    alt="team logo"
                                    fallbackSize={{ sm: 30 }}
                                    priority
                                />
                            )}
                        </Box>
                        <Typography variant="body2" fontWeight="medium">
                            {currentTeamInfo.teamName}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12} color="text.secondary" display="flex" flexDirection="column" justifyContent="center">
                    <Box>
                        <Typography variant="body2">Season Stats Unavailable</Typography>
                    </Box>
                </Grid>
            </Grid>
        </ListItem>
    )
}

export default WatchListPlayerViewSeasonStats
