import type { SelectListItem } from '../../../lib/types/popoverAndDrawerTypes'
import React from 'react'
import Typography from '@mui/material/Typography'
import GroupsIcon from '@mui/icons-material/Groups'
import { usePathname } from 'next/navigation'
import Stack from '@mui/material/Stack'
import { useSession } from 'next-auth/react'
import { useConstantsContext } from '../../../lib/contexts/ConstantsContext'
import {
    useGetPrevAndNextGameInfo,
    useGetStandingByTeamId,
    useTeam,
    useTeamProfileHeader,
    useTeams,
} from '../../../lib/hooks'
import { formatDateString, ordinalNumberSuffix } from '../../../lib/utils/formatters'
import ErrorState from '../../ErrorState'
import Link from '../../Link'
import EntityHeader from './EntityHeader'
import { formatNumber } from '@/lib/utils/math'

const getFormattedRecordAndStandingInConference = ({ wins, losses, seed, conference }: DTO.Standing): string =>
    `${wins}-${losses}${seed ? ` | ${seed}${ordinalNumberSuffix(seed)} in ${conference}` : ''}`

export const HomeOrAway = ({
    isHomeTeam,
    isWidget,
}: {
    isHomeTeam: boolean
    isWidget?: boolean
}): React.ReactElement => (
    <Typography fontSize={isWidget ? '0.875rem' : '1rem'} noWrap>
        {isHomeTeam ? 'vs.' : '@'}
    </Typography>
)
export const TeamAbbrLink = ({
    url,
    abbr,
    isWidget,
}: {
    url: string
    abbr: string
    isWidget?: boolean
}): React.ReactElement => (
    <Typography fontSize={isWidget ? '0.875rem' : '1rem'} noWrap>
        <Link href={`/teams/${url}/feed`} target="_blank" rel="noopener noreferrer">
            {abbr}
        </Link>
    </Typography>
)
const getFormattedGameScore = (game: DTO.PrevAndNextGameInfo['previousGameResult']): React.ReactElement => (
    <>
        {game.homeTeamScore && game.awayTeamScore && (
            <>
                {game.homeTeamScore > game.awayTeamScore ? game.homeTeamScore : game.awayTeamScore} &ndash;{' '}
                {game.homeTeamScore > game.awayTeamScore ? game.awayTeamScore : game.homeTeamScore}
            </>
        )}
    </>
)
type FormattedNextPrevGamesProps = {
    gameInfo: DTO.PrevAndNextGameInfo
    hasNBAStatsPermission: boolean
    hasGLGStatsPermission: boolean
    isWidget?: boolean
}
export const getFormattedNextPrevGames = ({
    gameInfo: { previousGameResult: prev, nextGameInfo: next },
    hasNBAStatsPermission,
    hasGLGStatsPermission,
    isWidget,
}: FormattedNextPrevGamesProps): React.ReactElement => (
    <Stack spacing={0.5} direction="row" flexWrap="wrap">
        {!isWidget && <Typography>Prev:</Typography>}
        {isWidget && prev.abbr && prev.urlSlug && (
            <TeamAbbrLink url={prev.urlSlug} abbr={prev.abbr} isWidget={isWidget} />
        )}
        <HomeOrAway isHomeTeam={prev.isHomeTeam} isWidget={isWidget} />
        <TeamAbbrLink url={prev.opponentUrlSlug} abbr={prev.opponentAbbr} isWidget={isWidget} />
        <Stack direction="row" spacing={0.5}>
            {prev.gameResult === 'win' ? (
                <Typography
                    sx={{
                        fontWeight: 'medium',
                        color: 'success.light',
                        ml: 1,
                        fontSize: isWidget ? '0.875rem' : '1rem',
                        marginLeft: 0,
                    }}
                >
                    W
                </Typography>
            ) : (
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        color: 'error.light',
                        ml: 1,
                        fontSize: isWidget ? '0.875rem' : '1rem',
                        marginLeft: 0,
                    }}
                >
                    L
                </Typography>
            )}
            {((prev.league === 'NBA' || prev.league === 'Summer') && hasNBAStatsPermission) ||
            ((prev.league === 'G-League' || prev.league === 'Summer') && hasGLGStatsPermission) ? (
                <Link
                    href={`/teams/${prev.urlSlug}/stats/report?timeFrame=GAME&gameIds=%5B"${prev.gameId}"%5D`}
                    fontSize={isWidget ? '0.875rem' : '1rem'}
                >
                    {getFormattedGameScore(prev)}
                </Link>
            ) : (
                <Typography fontSize={isWidget ? '0.875rem' : '1rem'} noWrap>
                    {getFormattedGameScore(prev)}
                </Typography>
            )}
        </Stack>
        {!isWidget && (
            <>
                <Typography mx="3px">|</Typography>
                <Typography>Next:</Typography>
                {next ? (
                    <>
                        <Typography>{formatDateString(next.gameDate, 'M/DD', 'utc')}</Typography>
                        <HomeOrAway isHomeTeam={next.isHomeTeam} />
                        <TeamAbbrLink url={next.opponentUrlSlug} abbr={next.opponentAbbr} />
                    </>
                ) : (
                    <Typography>TBD</Typography>
                )}
            </>
        )}
    </Stack>
)

type TeamEntityHeaderProps = {
    teamSlug: string | undefined
    isVisible: boolean
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const TeamEntityHeader = ({ teamSlug, isVisible, setIsVisible }: TeamEntityHeaderProps): JSX.Element | null => {
    const { scheduleYear } = useConstantsContext()
    const { data: session } = useSession()
    const { data: team, isLoading, isError } = useTeam(teamSlug)
    const {
        data: teams,
        isLoading: isTeamsLoading,
        isError: isTeamsError,
    } = useTeams(
        team?.leagueId,
        team?.leagueId === 'College' ||
            team?.leagueId === 'International' ||
            team?.leagueId === 'FIBA' ||
            team?.leagueId === 'OTE'
            ? team.conference
            : undefined
    )
    const { data: gameInfo, isLoading: isGameInfoLoading } = useGetPrevAndNextGameInfo(team?.teamId)
    const { data: standing, isLoading: isStandingLoading } = useGetStandingByTeamId(team?.teamId, scheduleYear)

    const { data: details, isInitialLoading: isLoadingDetails } = useTeamProfileHeader({
        teamId: team?.teamId,
        season: scheduleYear,
        league: team?.leagueId,
    })

    let summary: DTO.PlayerSummary[] | undefined
    if (details || isLoadingDetails) {
        if (team?.leagueId === 'G-League' || !session?.roles.contentPermissions['predictive-standings']) {
            summary = [
                {
                    label: 'Net Rating',
                    value: formatNumber(details?.stats.netRating, 1, 'decimal', undefined, true),
                    calloutKey: 'netRating',
                },
            ]
        } else if (team?.leagueId === 'NBA') {
            summary = [
                { label: 'Proj W/L', value: details?.projRecord || null, calloutKey: 'projRecord' },
                {
                    label: 'Net Rating',
                    value: formatNumber(details?.stats.netRating, 1, 'decimal', undefined, true),
                    calloutKey: 'netRating',
                },
            ]
        }
    }

    const pathname = usePathname()

    if (isError || isTeamsError) {
        return <ErrorState resourceName="the Team Layout" />
    }
    const url = `${pathname?.split('/').slice(3).join('/') as string}`

    const teamList: DTO.Team[] = []
    if (team?.affiliates) {
        if (url.includes('stats')) {
            const affiliates = team.affiliates.filter(
                (t) =>
                    (t.leagueId === 'NBA' && session?.roles.featurePermissions['nba-team-stats']) ||
                    (t.leagueId === 'G-League' && session?.roles.featurePermissions['glg-team-stats'])
            )
            teamList.push(...affiliates)
        } else {
            teamList.push(...team.affiliates)
        }
    }
    if (teams) teamList.push(...teams)

    const teamListItems: SelectListItem[] | undefined = teamList.length
        ? teamList
              .filter((t) => t.urlSlug !== teamSlug)
              .map((t) => ({
                  key: t.urlSlug,
                  text: t.teamName,
                  icon: t.url,
                  url: {
                      pathname: `/teams/${t.urlSlug}/${url}`,
                  },
              }))
        : undefined

    return (
        <EntityHeader
            isLoading={!teamSlug || isLoading || isStandingLoading || isGameInfoLoading || isTeamsLoading}
            name={team?.teamName}
            secondaryInfo={
                standing?.length && (standing[0].wins || standing[0].losses)
                    ? getFormattedRecordAndStandingInConference(standing[0])
                    : null
            }
            tertiaryInfo={
                gameInfo &&
                getFormattedNextPrevGames({
                    gameInfo,
                    hasNBAStatsPermission: !!session?.roles.featurePermissions['nba-team-stats'],
                    hasGLGStatsPermission: !!session?.roles.featurePermissions['glg-team-stats'],
                })
            }
            entityListItems={pathname?.includes('/posts') ? null : teamListItems}
            image={{ src: team?.url, aspectRatio: 1, fallbackIcon: <GroupsIcon /> }}
            placeholder={!teamSlug}
            entityType="team"
            urlSlug={teamSlug}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            summary={summary}
            isSummaryLoading={isLoadingDetails}
            teamStats={details?.stats}
        />
    )
}

export default TeamEntityHeader
