import { isNumber, safeRatio } from './math'

const amountInRange = (val: number, minValue: number, maxValue: number): number =>
    Math.max(0, Math.min(maxValue - minValue, val - minValue))

const efg = (
    twoPointAttempts: number,
    threePointAttempts: number,
    twoPointMade: number,
    threePointMade: number
): number | null => {
    // Fall back to default if no attempts
    if (twoPointAttempts + threePointAttempts === 0) {
        return 0.5
    }
    let efgVal = safeRatio(twoPointMade + 1.5 * threePointMade, twoPointAttempts + threePointAttempts)
    // Cap efg so values stay in a reasonable range
    if (efgVal !== null && efgVal > 0.8) {
        efgVal = 0.8
    }
    return efgVal
}

export type GameLOCBoxscore = {
    min: number | null | undefined
    trb: number | null | undefined
    '3pm': number | null | undefined
    '3pa': number | null | undefined
    '2pa': number | null | undefined
    '2pm': number | null | undefined
    fta: number | null | undefined
    ast: number | null | undefined
    stl: number | null | undefined
    blk: number | null | undefined
    to: number | null | undefined
    pts: number | null | undefined
}

export const calculateGameLOC = (
    level: 'PRO' | 'AM',
    { min, trb, '3pm': threePM, '3pa': threePA, '2pm': twoPM, '2pa': twoPA, ast, stl, blk, to, pts }: GameLOCBoxscore
): number | null => {
    if (
        isNumber(pts) &&
        isNumber(trb) &&
        isNumber(ast) &&
        isNumber(to) &&
        isNumber(blk) &&
        isNumber(stl) &&
        isNumber(twoPA) &&
        isNumber(twoPM) &&
        isNumber(threePA) &&
        isNumber(threePM)
    ) {
        const efgVal = efg(twoPA, threePA, twoPM, threePM)
        if (level === 'AM' && isNumber(efgVal)) {
            return (
                0.311934 +
                0.159606 * pts +
                0.083026 * trb +
                0.145355 * ast +
                -0.064402 * to +
                0.204604 * blk +
                0.186684 * stl +
                0.014383 * efgVal
            )
        }
        if (level === 'PRO' && isNumber(min) && isNumber(efgVal)) {
            const min1 = amountInRange(min, 0, 10)
            const min2 = amountInRange(min, 10, 20)
            const min3 = amountInRange(min, 20, 30)
            const pts1 = amountInRange(pts, 0, 6)
            const pts2 = amountInRange(pts, 6, 18)
            const pts3 = amountInRange(pts, 18, 50)
            const trb1 = amountInRange(trb, 0, 6)
            const trb2 = amountInRange(trb, 6, 30)
            const loc =
                1.24684 +
                0.04808 * min1 +
                0.03106 * min2 +
                0.06404 * min3 +
                -0.14367 * pts1 +
                -0.0559 * pts2 +
                0.11084 * pts3 +
                0.05876 * trb1 +
                0.08508 * trb2 +
                0.26666 * efgVal * pts +
                0.13976 * ast +
                0.17727 * Math.log(1 + stl * stl) +
                0.24487 * Math.log(1 + blk) +
                -0.15097 * Math.log(1 + to)
            return loc
        }
    }
    return null
}
